import type { TimeFormatter } from '../types';

// formatDate('2021-06-17T21:22:14+00:00');
export const formatDate: TimeFormatter<Partial<Intl.DateTimeFormatOptions>> = (
  input,
  opts
) => {
  if (!input) return null;

  let options: Intl.DateTimeFormatOptions = {
    // timeZone: 'America/New_York',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    ...opts,
  };

  options = Object.fromEntries(
    Object.entries(options).filter(([_, v]) => v && v !== null)
  );

  const output = new Intl.DateTimeFormat('default', options).format(
    input instanceof Date ? input : Date.parse(input.toString())
  );

  return output ?? null;
};
